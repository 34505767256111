import React, { useState } from 'react';
import './ProfileTab.css'; // Ensure you have corresponding styles
import { updateContact } from '../../../../api/auth';

const ProfileTab = ({ contact }) => {
  const [notes, setNotes] = useState(contact.notes);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(contact.content_status);

  const handleEditClick = () => {
    setSelectedConsent(contact.content_status ? contact.content_status : 'Subscribed'); // Reset to current status
    setIsModalOpen(true);
  };

  const handleSave = () => {
    contact.consent_status = selectedConsent;
    contact.notes = notes;
    updateContact(contact).then((response) => {
      contact = response.contact;
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const resendConsentRequest = () => {
  };

  return (
    <div>
      <h3>Email: {contact.email}</h3>
      <div className="profile-status">
        <span>Consent status: </span>
        {contact.consent_status ? (
          <>
            <span className="consent-status">{contact.consent_status}</span>
            <icon className="edit-consent-btn" onClick={handleEditClick}>
              &#9998; {/* Edit icon (pencil) */}
            </icon>
            {contact.consent_request_status === 'Sent' && (
              <span className="consent-not-status">Consent pending</span>
            )}
          </>
        ) : (
          <span className="consent-not-status">Consent status not defined</span>
        )}

        {(contact.consent_request_status !== 'Approved' && contact.consent_request_status !== 'Deny')
          ? <button className="resend-consent-btn" onClick={resendConsentRequest}>Resend consent request</button>
          : <span className="consent-not-status">by consent request</span>
        }
      </div>

      <div className="profile-privacy">
        <a href="#privacy">Privacy Management</a> | <a href="#gdpr">GDPR Compliance Guide</a>
      </div>

      <div className="profile-notes">
        <label>Notes</label>
        <textarea placeholder="Empty" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
      </div>
      {notes && <div className="modal-buttons">
        <button className="save-btn" onClick={handleSave}>Save</button>
      </div>}
      {/* Modal for editing consent status */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Edit Consent Status</h3>
            <select
              value={selectedConsent}
              onChange={(e) => setSelectedConsent(e.target.value)}
            >
              <option value="Subscribed">Subscribed</option>
              <option value="Unsubscribed">Unsubscribed</option>
            </select>
            <div className="modal-buttons">
              <button className="save-btn" onClick={handleSave}>Save</button>
              <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileTab;