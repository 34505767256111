import React, { useEffect, useState } from 'react';
import './Contacts.css';
import { getContacts } from '../../../api/auth';
import DownloadCSV from '../../other/DownloadCSV';
import Contact from './Contact/Contact';

const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showUnsubscribed, setShowUnsubscribed] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null); // State to track the selected contact
    const downloadUrl = process.env.REACT_APP_API_URL + '/contacts/download'; // Dynamic URL for CSV download
    const fileName = 'contacts.csv';

    useEffect(() => {
        fetchContacts();
    }, []);

    const fetchContacts = (q = null, showUnsubscribed = false) => {
        getContacts(null, null, q, showUnsubscribed).then((response) => {
            setContacts(response.contacts);
        });
    };

    useEffect(() => {
        fetchContacts(searchTerm, showUnsubscribed);
    }, [searchTerm, showUnsubscribed]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleContactClick = (contact) => {
        setSelectedContact(contact); // Set the clicked contact as selected
    };

    const handleBackToList = () => {
        setSelectedContact(null); // Reset the selected contact to go back to the list
    };

    // Conditional rendering based on selected contact
    return (
        <div className="contacts-container">
            {!selectedContact ? (
                <>
                    <h1>Contacts</h1>
                    <div className="contacts-header">
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Search contacts..."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="filter">
                            <input
                                type="checkbox"
                                checked={showUnsubscribed}
                                onChange={e => setShowUnsubscribed(e.target.checked)}
                            />
                            <label>Show only unsubscribed</label>
                        </div>
                        <DownloadCSV url={downloadUrl} fileName={fileName} />
                    </div>
                    <p>{contacts.length} contacts</p>
                    <table className="contacts-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Last Contacted On</th>
                                <th>Unsubscribed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((contact, index) => (
                                <tr key={index} onClick={() => handleContactClick(contact)}>
                                    <td>{contact.name}</td>
                                    <td>{contact.email}</td>
                                    <td>{formatDate(contact.last_contacted_on)}</td>
                                    <td>{contact.unsubscribe ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <>
                    <button onClick={handleBackToList} className="back-button">Back to Contacts</button>
                    <Contact contact={selectedContact} /> {/* Pass the selected contact to the Contact component */}
                </>
            )}
        </div>
    );
};

export default Contacts;
