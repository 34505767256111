import React, { useState } from 'react';
import './Contact.css';
import ProfileTab from './ProfileTab';
import EmailTracking from '../../email-tracking/EmailTracking';

const Contact = ({ contact }) => {
    const [activeTab, setActiveTab] = useState('Communications');
    if (!contact) return null;

    return (
        <div className="contact-container">
            <h6>Contacts / {contact.email}</h6>
            <h1>{contact.email}</h1>

            {/* Tab Menu and other details */}
            {/* Example details using the contact prop */}
            <div className="tabs">
                <button
                    className={activeTab === 'Communications' ? 'active' : ''}
                    onClick={() => setActiveTab('Communications')}
                >
                    <i className="icon-comm"></i> Communications
                </button>
                <button
                    className={activeTab === 'Profile' ? 'active' : ''}
                    onClick={() => setActiveTab('Profile')}
                >
                    <i className="icon-profile"></i> Profile
                </button>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {activeTab === 'Communications' && <EmailTracking contact={contact} />}
                {activeTab === 'Profile' && <ProfileTab contact={contact} />}
            </div>
        </div>
    );
};

export default Contact;
