import React from 'react';
import axios from 'axios';

const DownloadCSV = ({ url, fileName }) => {
  const downloadCsvFile = async () => {
    try {
      // Make an authorized request to get the CSV file
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Pass the token from props
        },
        responseType: 'blob', // Important to handle file responses
      });

      // Create a URL for the file to trigger download
      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName); // Use the fileName from props
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the CSV file:', error);
    }
  };

  return (
    <button onClick={downloadCsvFile} className="csv-download-button">
      <span className="csv-icon">&#128190;</span> Download CSV
    </button>
  );
};

export default DownloadCSV;