import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import './Dashboard.css';  // Import the component-specific CSS
import EmailTracking from './email-tracking/EmailTracking';
import ClickReport from './click-report/ClickReport';
import Contacts from './contacts/Contacts';

function Dashboard() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <div className="dashboard">
      <header className="header">
        <h1>Email Tracker</h1>
        <div className="header-actions">
          <span>Hello {user?.name}</span>
          <button className="icon-btn" title="Help">?</button>
          <button className="icon-btn" title="Settings">⚙️</button>
          <button className="user-btn" onClick={handleLogout}>
            {'Logout'}
          </button>
        </div>
      </header>
      <div className="content">
        <aside className="sidebar">
          <nav>
            <ul>
              <li>
                <Link to="/dashboard/email-tracking">
                  <i className="fas fa-envelope"></i> Email Tracking
                </Link>
              </li>
              <li>
                <Link to="/dashboard/click-report">
                  <i className="fas fa-mouse-pointer"></i> Click Report
                </Link>
              </li>
              <li>
                <Link to="/dashboard/campaigns">
                  <i className="fas fa-bullhorn"></i> Campaigns
                </Link>
              </li>
              <li>
                <Link to="/dashboard/documents">
                  <i className="fas fa-file-alt"></i> Documents
                </Link>
              </li>
              <li>
                <Link to="/dashboard/email-productivity">
                  <i className="fas fa-chart-line"></i> Email Productivity
                </Link>
              </li>
              <li>
                <Link to="/dashboard/contacts">
                  <i className="fas fa-address-book"></i> Contacts
                </Link>
              </li>
              <li>
                <Link to="/dashboard/templates">
                  <i className="fas fa-file-alt"></i> Templates
                </Link>
              </li>
              <li>
                <Link to="/dashboard/integrations">
                  <i className="fas fa-plug"></i> Integrations
                </Link>
              </li>
              <li>
                <Link to="/dashboard/team">
                  <i className="fas fa-users"></i> Team
                </Link>
              </li>
              <li>
                <Link to="/dashboard/settings">
                  <i className="fas fa-cog"></i> Settings and Privacy
                </Link>
              </li>
            </ul>
          </nav>
        </aside>
        <main className="main-content">
        <Routes>
          <Route index element={<Navigate to="email-tracking" replace />} />
          <Route path="/email-tracking" element={<EmailTracking />} />
          <Route path="click-report" element={<ClickReport />} />
          {/* <Route path="campaigns" element={<Campaigns />} />
          <Route path="documents" element={<Documents />} />
          <Route path="email-productivity" element={<EmailProductivity />} /> */}
          <Route path="contacts" element={<Contacts />} />
          {/* <Route path="templates" element={<Templates />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="team" element={<Team />} />
          <Route path="settings" element={<SettingsAndPrivacy />} /> */}
        </Routes>
        </main>
      </div>
      <footer className="footer">
        <p>&copy; 2024 Email Tracker. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Dashboard;