import React, { useEffect, useContext, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { logoutUser } from '../../api/auth';

function Logout() {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const logoutCalled = useRef(false);

  const performLogout = useCallback(async () => {
    if (logoutCalled.current) return;
    logoutCalled.current = true;
    try {
      await logoutUser();
      setUser(null);
      navigate('/login');
      localStorage.removeItem('access_token');
    } catch (error) {
      console.error('Logout failed:', error);
      setUser(null);
      navigate('/login');
      localStorage.removeItem('access_token');
    }
  }, [setUser, navigate]);

  useEffect(() => {
    performLogout();
  }, [performLogout]);

  return <div>Logging out...</div>;
}

export default Logout;